body {
  overflow: hidden;
}

.chakra-stack.datepicker-calendar > div {
  border-width: 0;
}

@media print {
  .no-break {
    page-break-inside: avoid;
  }
}